import {PageTitle} from '../../../_metronic/layout/core'
import {MixedWidget1} from '../../../_metronic/partials/widgets'
import {useEffect, useState} from 'react'
import {sendRequest} from '../../../Helpers/Requests'
import Table from '../../modules/table/Table'
import {getColumns} from './extrato/columns'
import {rowModel} from './extrato/rows'

const Extrato = () => {
  const [rows, setRows] = useState<any>(null)
  const [binario, setBinario] = useState(0)
  const [debitos, setDebitos] = useState(0)
  const [diarios, setDiarios] = useState(0)
  const [entradas, setEntradas] = useState(0)
  const [indicacao, setIndicacao] = useState(0)
  const [residual, setResidual] = useState(0)
  const [saidas, setSaidas] = useState(0)
  const [saques, setSaques] = useState(0)
  const [unilevel, setUnilevel] = useState(0)

  const loadBalance = async () => {
    const req = await sendRequest(`usuario/balanco`, 'GET')
    const response = await req

    if (response.tipo === 'success') {
      const {
        balanco,
        binario_total,
        debitos_total,
        diario_total,
        entradas_total,
        indicacao_total,
        residual_total,
        saidas_total,
        saques_total,
        unilevel_total,
      } = response

      setRows(balanco)
      setBinario(binario_total)
      setDebitos(debitos_total)
      setDiarios(diario_total)
      setEntradas(entradas_total)
      setIndicacao(indicacao_total)
      setResidual(residual_total)
      setSaidas(saidas_total)
      setSaques(saques_total)
      setUnilevel(unilevel_total)
    }
  }

  useEffect(() => {
    loadBalance()
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>Financial History</PageTitle>

      <div className='row g-5 g-xl-8'>
        <div className='col-md-6 col-sm-12'>
          <MixedWidget1
            title='Your Entries'
            desc='Entradas'
            className='card-xl-stretch mb-xl-8'
            color='primary'
            binario={binario}
            diario={diarios}
            entradas={entradas}
            indicacao={indicacao}
            residual={residual}
            unilevel={unilevel}
          />
        </div>

        <div className='col-md-6 col-sm-12'>
          <MixedWidget1
            title='Your Outputs'
            desc='Saídas'
            className='card-xl-stretch mb-xl-8'
            color='danger'
            debitos={debitos}
            entradas={saidas}
            saques={saques}
          />
        </div>
      </div>

      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-sm-12'>
          <div className='card card-flush'>
            <div className='card-body d-flex align-items-end pt-5'>
              <Table columns={getColumns()} data={rows} rowModel={rowModel} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {Extrato}
