import {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'
import {ModalPixSendPlano} from './ModalPixSendPlano'
const ModalPagamento = (props: any) => {
  const [pixModal, setPixModal] = useState(false)
  const {paymodal, hidePaymodal, subscription, handlePayment, loadPayment, setSubscription, config} = props

  const payPlan = (button: any) => {
    if (button.method === 'Pix') {
      hidePaymodal()
      return setPixModal(true)
    }

    handlePayment(button)
  }

  if (!subscription) return <></>

  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-500px'
        show={paymodal}
        onHide={hidePaymodal}
        backdrop={true}
      >
        <div className='modal-header'>
          <h2>Pay Plan</h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={hidePaymodal}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>
        <div className='modal-body py-lg-10 px-lg-10'>
          <div className='row mt-2'>
            <div className='col-lg-12'>
              <h4>
                Plan: <span className='notranslate'>{subscription.plan.nome}</span>
              </h4>
              <span>
                Value: <span className='notranslate'>{config.moeda.prefixo}{(subscription.difference !== null)?subscription.difference:subscription.plan.valor}</span>
              </span>
            </div>
          </div>
          <div className='row mt-2'>
            <div className='col-lg-12 d-flex flex-column align-items-center justify-content-between'>
              {subscription.plan.buttons.map((btn: any, index: any) => (
                <button
                  key={`btn${index}`}
                  type='reset'
                  className='btn btn-secondary btn-block'
                  onClick={() => payPlan(btn)}
                  disabled={loadPayment}
                  style={{width: '100%', marginTop: '0.5rem'}}
                >
                  <>
                    <img src={btn.prepend_image} style={{height: '25px', marginRight: '0.5rem'}} />
                    {btn.title}
                  </>
                </button>
              ))}
            </div>
          </div>
        </div>

        <div className='modal-footer py-lg-10 px-lg-10'>
          <button className='btn btn-danger' style={{width: '100%'}} onClick={hidePaymodal}>
            Cancel
          </button>
        </div>
      </Modal>
      <ModalPixSendPlano
        valor={(subscription.difference !== null)?subscription.difference:subscription.plan.valor}
        pixModal={pixModal}
        hidePixModal={() => setPixModal(false)}
        subscription={subscription}
      />
    </>
  )
}

export {ModalPagamento}
