/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useContext } from 'react'
import { Dropdown1 } from '../../content/dropdown/Dropdown1'
import { KTSVG } from '../../../helpers'
import { sendRequest } from '../../../../Helpers/Requests'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import ConfigContext from '../../../../app/contexts/Config/ConfigContext'

type Props = {
    title: string
    className: string
    color: string
    balance?: any,
    tax?: any
    lastWithdraw?: any
    minWithdraw?: any
    wallets?: any
    methods?: any
    type?: any
}

const MixedCustomToWithdraw: React.FC<Props> = ({ title, className, color, balance, tax, lastWithdraw, minWithdraw, wallets, methods, type }) => {
    const navigate = useNavigate()

    const [valor, setValor] = useState<any>(0)
    const [carteira, setCarteira] = useState<any>(null)
    const [metodo, setMetodo] = useState<any>(null)
    const [tipo, setTipo] = useState<any>(null)

    const id = ''
    const config = useContext(ConfigContext)

    const handleWithdraw = async () => {
        if (parseFloat(balance) > 0 && carteira != null && metodo != null) {
            const req = await sendRequest('saques', 'POST', {
                carteira: wallets[carteira].carteira,
                id: wallets[carteira].id_usuario,
                metodo: 'wallet',
                tipo: type,
                valor: balance
            })
            const response = await req

            if (response.tipo === 'success') {
                navigate('/meus-saques')
            }
        } else {
            toast.error('Preencha todos os campos.')
        }
    }

    useEffect(() => {
        setCarteira(0)
        setMetodo(0)
    }, [wallets, methods])

    return (
        <div className={`card ${className}`}>
            {/* begin::Body */}
            <div className='card-body p-0'>
                {/* begin::Header */}
                <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-${color}`}>
                    {/* begin::Heading */}
                    <div className='d-flex flex-stack'>
                        <h3 className='m-0 text-white fw-bold fs-3'>{title}</h3>
                        <div className='ms-1'>

                        </div>
                    </div>
                    {/* end::Heading */}
                    {/* begin::Balance */}
                    <div className='d-flex text-center flex-column text-white pt-8'>
                        <span className='fw-semibold fs-7'>Available balance</span>
                        <span className='fw-bold fs-2x pt-1'>
                            {config.moeda.prefixo} {parseFloat(balance).toFixed(2)}
                        </span>
                    </div>
                    {/* end::Balance */}
                </div>
                {/* end::Header */}
                {/* begin::Items */}
                <div
                    className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body'
                    style={{ marginTop: '-100px' }}
                >
                    {/* begin::Item */}
                    <div className='d-flex align-items-center mb-6'>
                        {/* begin::Symbol */}
                        <div className='symbol symbol-45px w-40px me-5'>
                            <span className='symbol-label bg-lighten'>
                                <KTSVG path='/media/icons/duotune/maps/map004.svg' className='svg-icon-1' />
                            </span>
                        </div>
                        {/* end::Symbol */}
                        {/* begin::Description */}
                        <div className='d-flex align-items-center flex-wrap w-100'>
                            {/* begin::Title */}
                            <div className='mb-1 pe-3 flex-grow-1'>
                                <span className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                                Minimum withdrawal
                                </span>
                                <div className='text-gray-400 fw-semibold fs-7'>Minimum to withdrawal</div>
                            </div>
                            {/* end::Title */}
                            {/* begin::Label */}
                            <div className='d-flex align-items-center'>
                                <div className='fw-bold fs-5 text-gray-800 pe-1'>
                                    {config.moeda.prefixo}{parseFloat(minWithdraw).toFixed(2)}
                                </div>
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr066.svg'
                                    className='svg-icon-5 svg-icon-warning ms-1'
                                />
                            </div>
                            {/* end::Label */}
                        </div>
                        {/* end::Description */}
                    </div>
                    {/* end::Item */}

                    {/* begin::Item */}
                    <div className='d-flex align-items-center mb-6'>
                        {/* begin::Symbol */}
                        <div className='symbol symbol-45px w-40px me-5'>
                            <span className='symbol-label bg-lighten'>
                                <KTSVG path='/media/icons/duotune/maps/map004.svg' className='svg-icon-1' />
                            </span>
                        </div>
                        {/* end::Symbol */}
                        {/* begin::Description */}
                        <div className='d-flex align-items-center flex-wrap w-100'>
                            {/* begin::Title */}
                            <div className='mb-1 pe-3 flex-grow-1'>
                                <span className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                                    Fee
                                </span>
                                <div className='text-gray-400 fw-semibold fs-7'>Withdrawal fee</div>
                            </div>
                            {/* end::Title */}
                            {/* begin::Label */}
                            <div className='d-flex align-items-center'>
                                <div className='fw-bold fs-5 text-gray-800 pe-1'>
                                    {tax}%
                                </div>
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr066.svg'
                                    className='svg-icon-5 svg-icon-warning ms-1'
                                />
                            </div>
                            {/* end::Label */}
                        </div>
                        {/* end::Description */}
                    </div>
                    {/* end::Item */}

                    {lastWithdraw !== null &&
                        <>
                            {/* begin::Item */}
                            <div className='d-flex align-items-center mb-6'>
                                {/* begin::Symbol */}
                                <div className='symbol symbol-45px w-40px me-5'>
                                    <span className='symbol-label bg-lighten'>
                                        <KTSVG path='/media/icons/duotune/maps/map004.svg' className='svg-icon-1' />
                                    </span>
                                </div>
                                {/* end::Symbol */}
                                {/* begin::Description */}
                                <div className='d-flex align-items-center flex-wrap w-100'>
                                    {/* begin::Title */}
                                    <div className='mb-1 pe-3 flex-grow-1'>
                                        <span className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                                            Last
                                        </span>
                                        <div className='text-gray-400 fw-semibold fs-7'>Last withdrawal</div>
                                    </div>
                                    {/* end::Title */}
                                    {/* begin::Label */}
                                    <div className='d-flex align-items-center'>
                                        <div className='fw-bold fs-5 text-gray-800 pe-1'>
                                            {config.moeda.prefixo} {lastWithdraw.valor}
                                        </div>
                                        <KTSVG
                                            path='/media/icons/duotune/arrows/arr066.svg'
                                            className='svg-icon-5 svg-icon-warning ms-1'
                                        />
                                    </div>
                                    {/* end::Label */}
                                </div>
                                {/* end::Description */}
                            </div>
                            {/* end::Item */}
                        </>
                    }

                    <div className="mb-7">
                        <label className='mb-3'>Receiving method</label>
                        <select className='form-control' onChange={e => setMetodo(e.target.value)}>
                            {methods != null &&
                                <>
                                    {methods.map((item: any, key: number) => (
                                        <option key={key} value={key}>{item.label}</option>
                                    ))}
                                </>
                            }
                        </select>
                    </div>

                    <div className="mb-7">
                        <label className='mb-3'>Withdrawal amount</label>
                        <input className='form-control' value={balance.toFixed(2)} onChange={e => setValor(e.target.value)} readOnly />
                    </div>

                    <div className="mb-7">
                        <label className='mb-3'>Wallet</label>
                        <select className='form-control' onChange={e => setCarteira(e.target.value)}>
                            {wallets != null &&
                                <>
                                    {wallets.map((item: any, key: number) => (
                                        <option value={key}>{item.carteira}</option>
                                    ))}
                                </>
                            }
                        </select>
                    </div>

                    <button className={`btn btn-${color}`} style={{ width: '100%' }} onClick={handleWithdraw}>Send Request</button>

                </div>
                {/* end::Items */}
            </div>
            {/* end::Body */}
        </div>
    )
}

export { MixedCustomToWithdraw }
