/* eslint-disable jsx-a11y/anchor-is-valid */
import {Fragment} from 'react'
import {KTSVG} from '../../../../helpers'
import clsx from 'clsx'
import {toAbsoluteUrl} from '../../../../helpers'

type Props = {
  className: string
  description: string
  icon: boolean
  stats: number
  labelColor: string
  textColor: string
  direita?: any
  esquerda?: any
  pontosDireita?: any
  pontosEsquerda?: any
  binario?: any
  config?: any
  carreira?: any
  pontosCarreira?: any
  loaded?: any
}

const items: Array<{
  name: string
  initials?: string
  src?: string
  state?: string
}> = [
  {name: 'Alan Warden', initials: 'A', state: 'warning'},
  {name: 'Michael Eberon', src: toAbsoluteUrl('/media/avatars/300-11.jpg')},
  {name: 'Susan Redwood', initials: 'S', state: 'primary'},
  {name: 'Melody Macy', src: toAbsoluteUrl('/media/avatars/300-2.jpg')},
  {name: 'Perry Matthew', initials: 'P', state: 'danger'},
  {name: 'Barry Walter', src: toAbsoluteUrl('/media/avatars/300-12.jpg')},
]

const CardsWidget7 = ({
  pontosDireita,
  pontosEsquerda,
  direita,
  esquerda,
  className,
  description,
  icon,
  stats,
  labelColor,
  textColor,
  config,
  binario,
  carreira,
  pontosCarreira,
  loaded,
}: Props) => (
  <div className={`card card-flush ${className} ${loaded ? '' : 'overlay overlay-block'}`}>
    <div className='card-header pt-5'>
      <div className='card-title d-flex flex-column'>
        <div className='card-title d-flex flex-column'>
          <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{stats}</span>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>{description}</span>
        </div>
      </div>
    </div>
    <div className='card-body d-flex flex-column justify-content-between pe-0'>
      <div className='overlay-wrapper'>
        <div className='row'>
          <div className='col-lg-12'>
            <Fragment key={`lw26-rows-binario`}>
              <div className='d-flex flex-stack'>
                <a href='#' className='text-primary fw-semibold fs-6 me-2'>
                  Binary: {binario ? 'Qualified' : 'Not Qualified'}
                </a>
              </div>
              <div className='separator separator-dashed my-3 me-10' />
            </Fragment>

            {config.carreira && (
              <Fragment key={`lw26-rows-carreira`}>
                <div className='d-flex flex-stack'>
                  <a href='#' className='text-primary fw-semibold fs-6 me-2 notranslate'>
                  Career: {carreira}{' '}
                    <span className='fs-smaller text-warning ml-1'>{pontosCarreira} pts</span>
                  </a>
                </div>
                <div className='separator separator-dashed my-3 me-10' />
              </Fragment>
            )}
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <strong className='fs-title text-primary'>Left</strong>
            <br />
            <strong>Users: <span className='notranslate'>{esquerda}</span></strong>
            <br />
            <strong>Binary: <span className='notranslate'>{pontosEsquerda}</span></strong>
            <br />
          </div>
          <div className='col-6'>
            <strong className='fs-title text-primary'>Right</strong>
            <br />
            <strong>Users: <span className='notranslate'>{direita}</span></strong>
            <br />
            <strong>Binary: <span className='notranslate'>{pontosDireita}</span></strong>
            <br />
          </div>
        </div>
      </div>
      {!loaded && (
        <div className='overlay-layer rounded bg-dark bg-opacity-5'>
          <div className='spinner-border text-primary' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      )}
    </div>
  </div>
)
export {CardsWidget7}
