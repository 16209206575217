/* eslint-disable jsx-a11y/anchor-is-valid */
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, login } from '../core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'
import { baseUrl, sendRequest } from '../../../../Helpers/Requests'
import { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import useGTranslator from '../../gTranslator/hooks/useGTranslator'
import LanguageSelector from '../../gTranslator/components/LanguageSelector'
import { ModalPagamentoCadastro } from './ModalPagamentoCadastro'
import ConfigContext from '../../../contexts/Config/ConfigContext'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const config = useContext(ConfigContext)
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()

  const [user, setUser] = useState<any>(null)
  const [loginC, setLogin] = useState('')
  const [senha, setSenha] = useState('')
  const [payModal, setPayModal] = useState(false)
  const [licenca, setLicenca] = useState<any>(null)
  const [paymentButtons, setPaymentButtons] = useState<any>(null)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => { },
  })

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const req = await sendRequest(`login`, 'POST', {
        login: loginC,
        senha,
      })

      const user = await req.usuario

      if (user.email_verified_at === null) {
        setLicenca(parseFloat(req.valor))
        setPaymentButtons(req.paymentMethods)
        setUser(req.usuario)
        setPayModal(true)
        return false
      }

      saveAuth({
        api_token: req.token,
        refreshToken: req.token,
      })

      setCurrentUser({
        id: 1,
        username: user.login,
        password: undefined,
        email: user.email,
        first_name: user.nome.split(' ')[0],
        last_name: user.nome.split(' ')[1] != undefined ? user.nome.split(' ')[1] : '',
        fullname: user.nome,
        phone: user.celular,
        pic: user.imagem,
        language: 'pt',
      })
    } catch (error) {
      setLogin('')
      setSenha('')
      saveAuth(undefined)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <form className='form w-100' noValidate id='kt_login_signin_form'>
        {/* begin::Heading */}
        <div className='text-center mb-11'>
          <Link to='/' className='mb-12'>
            {config.logo && config.logo !== "" && <img alt='Logo' src={baseUrl(config.logo)} className='h-75px notranslate' />}
          </Link>
        </div>
        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>Access Your Account</h1>
        </div>
        {/* begin::Heading */}


        {/* begin::Separator */}
        <div className='separator separator-content my-14 text-light'>
          <span className='w-125px  fw-semibold fs-7'>Credentials</span>
        </div>
        {/* end::Separator */}

        {/* begin::Form group */}
        {/* <LanguageSelector
          containerClassName='fv-row mb-8'
          labelClassName='form-label fs-6 fw-bolder text-dark'
          loginPage={true}
        /> */}
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>Login</label>
          <input
            placeholder='Login'
            {...formik.getFieldProps('email')}
            className={clsx('form-control bg-transparent')}
            type='text'
            name='email'
            autoComplete='off'
            onChange={(t) => setLogin(t.target.value)}
            value={loginC}
          />
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-3'>
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
          <input
            placeholder='Senha'
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent'
              // {
              //   'is-invalid': formik.touched.password && formik.errors.password,
              // },
              // {
              //   'is-valid': formik.touched.password && !formik.errors.password,
              // }
            )}
            onChange={(t) => setSenha(t.target.value)}
            value={senha}
          />
        </div>
        {/* end::Form group */}

        {/* begin::Wrapper */}
        <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
          <div />

          {/* begin::Link */}
          {/* <Link to='/auth/forgot-password' className='link-success'>
            Esqueceu sua senha ?
          </Link> */}
          {/* end::Link */}
        </div>
        {/* end::Wrapper */}

        {/* begin::Action */}
        <div className='d-grid mb-10'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn'
            style={{backgroundColor: '#ffd127'}}
            disabled={loading}
            onClick={handleSubmit}
          >
            {!loading && <span className='indicator-label'>Login</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}

        {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
          Ainda não tem sua conta?{' '}
          <Link to='/auth/registration' className='link-primary'>
            Criar conta
          </Link>
            </div>*/}
      </form>

      {config.licenca && licenca && paymentButtons && user && (
        <ModalPagamentoCadastro
          paymodal={payModal}
          hidePaymodal={() => setPayModal(false)}
          userId={user ? user.id : null}
          user={user}
          setUser={setUser}
          licenca={licenca}
          paymentButtons={paymentButtons}
        />
      )}
    </>
  )
}
