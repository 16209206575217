import toast from 'react-hot-toast'

const logoutAfterSeconds = (seconds = 3) => {
  const miliseconds = seconds * 1000
  setTimeout(() => {
    window.location.href = '/logout'
  }, miliseconds)
}

export const verifyErrors = (error) => {
  if (!window.navigator.onLine) {
    return toast.error('Você se encontra offline, por favor, verifique sua conexão.')
  }
  if (typeof error === 'undefined' || typeof error.status === 'undefined') {
    return toast.error('Erro de conexão com o servidor, contate o suporte.')
  }
  if (error.response.status == 422) {
    let keys = Object.keys(error.response.data.errors)
    keys.forEach((key) => {
      error.response.data.errors[key].forEach((err) => {
        toast.error(err)
      })
    })
    return
  }
  if (error.response.status === 401) {
    logoutAfterSeconds(3)
    return toast.error('Token expirado, por favor, faça o login novamente.')
  }
  if (error.response.status === 404) {
    logoutAfterSeconds(3)
    return toast.error('Conteúdo não encontrado, contate o suporte.')
  }
  if (error.response.status === 400) {
    logoutAfterSeconds(3)
    return toast.error('Erro de requisição, contate o suporte.')
  }
  if (error.response.status === 403) {
    return toast.error('Este não tem privilégios de acesso a este conteúdo.')
  }

  return toast.error('Falha interna do servidor contate suporte.')
}
