import {PageTitle} from '../../../_metronic/layout/core'
import {useState, useEffect, useContext} from 'react'
import {sendRequest} from '../../../Helpers/Requests'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import ConfigContext from '../../contexts/Config/ConfigContext'
import {CircularProgress} from '@mui/material'
import Table from '../../modules/table/Table'
import {getColumns} from './unilevel/columns'
import {rowModel} from './unilevel/rows'

const Unilevel = () => {
  const config = useContext(ConfigContext)
  const [levels, setLevels] = useState(0)
  const [currentLevel, setCurrentLevel] = useState(1)
  const [rows, setRows] = useState<any>(null)

  const getLevels = async () => {
    const req = await sendRequest(`rede/unilevel/niveis`, 'GET')

    const response = await req

    if (response.tipo === 'success') {
      setLevels(response.niveis)
    }
  }

  const returnLevelsButtons = () => {
    let buttons = []
    for (let i = 1; i < levels + 1; i++) {
      buttons.push(renderLevelsButtons(i))
    }
    return buttons
  }

  const renderLevelsButtons = (level: any) => {
    return (
      <li className='nav-item'>
        <button
          className={`nav-link ${level === currentLevel ? 'active' : ''}`}
          onClick={() => {
            setCurrentLevel(level)
          }}
        >
          Level: {level}
        </button>
      </li>
    )
  }

  const loadLevel = async (level: any) => {
    const req = await sendRequest(`rede/unilevel/nivel/${level}`, 'GET')
    const response = await req

    if (response.tipo === 'success') {
      setRows(response.unilevel)
    }
  }

  useEffect(() => {
    getLevels()
  }, [])

  useEffect(() => {
    loadLevel(currentLevel)
  }, [currentLevel])

  return (
    <>
      <PageTitle>Unilevel</PageTitle>

      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-sm-12'>
          <div className='card card-flush' style={{padding: '25px'}}>
            <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
              {returnLevelsButtons()}
            </ul>
            <div className='tab-content' id='myTabContent'>
              <Table columns={getColumns(config.binario)} data={rows} rowModel={rowModel} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {Unilevel}
