import React, { useEffect } from 'react';

const GoogleTranslate = () => {
  useEffect(() => {
    const addGoogleTranslate = () => {
      if (window.google && window.google.translate) {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: 'en', // Idioma padrão da página
            includedLanguages: 'en,es,fr,de,pt', // Idiomas para tradução
            layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
          },
          'google_translate_element'
        );
      }
    };

    const loadGoogleTranslate = () => {
      // Aguarde alguns segundos antes de chamar addGoogleTranslate para garantir o carregamento do script
      setTimeout(addGoogleTranslate, 1000); // Atraso de 1 segundo
    };

    loadGoogleTranslate();
  }, []);

  return <div id="google_translate_element"></div>;
};

export default GoogleTranslate;