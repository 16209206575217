import { useEffect, useState, useContext } from "react"
import { sendRequest } from "../../../Helpers/Requests"
import { PageTitle } from "../../../_metronic/layout/core"
import ConfigContext from "../../contexts/Config/ConfigContext"

const MeusSaques = () => {
    const config = useContext(ConfigContext)
    const [ rows, setRows ] = useState<any>([])

    const loadMyWithdraws = async () => {
        const req = await sendRequest('saques', 'GET')
        const response = await req

        if (response.tipo === 'success') {
            setRows(response.pedidos)
        }
    }

    const renderRows = (item:any, key:number) => {
        return (
            <tr key={key}>
                <td>#{item.id}</td>
                <td>{item.created_at}</td>
                <td>
                    {item.tipo_saque === "rendimento" &&
                        <div className="badge badge-primary">Revenue</div>
                        ||
                        <div className="badge badge-info">Bonus</div>
                    }
                </td>
                <td>${parseFloat(item.valor).toFixed(2)}</td>
                <td>{item.desconto_pct}%</td>
                <td>{config.moeda.prefixo}{parseFloat(item.valor_liq).toFixed(2)}</td>
                <td>
                    {item.status === 'pendente' &&
                        <div className="badge badge-warning">Pending</div>
                        || item.status === 'processamento' &&
                        <div className="badge badge-info">Processing</div>
                        ||
                        <div className="badge badge-success">Completed</div>
                    }
                </td>
            </tr>
        )
    }

    useEffect(() => {
        loadMyWithdraws()
    }, [])

    return(
        <>
            <PageTitle breadcrumbs={[]}>My Withdrawals</PageTitle>
            
            <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
                <div className="col-sm-12">
                    <div className="card card-flush">
                        <div className="card-body d-flex align-items-end pt-5">
                            <div className='table-responsive' style={{ width: '100%' }}>
                                {/* begin::Table */}
                                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                    {/* begin::Table head */}
                                    <thead>
                                        <tr className='fw-bold text-muted'>
                                            <th className=''>
                                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                    ID
                                                </div>
                                            </th>
                                            <th className=''>Created</th>
                                            <th className=''>Type</th>
                                            <th className=''>Value</th>
                                            <th className=''>Tax %</th>
                                            <th className=''>Liquid</th>
                                            <th className=''>Status</th>
                                        </tr>
                                    </thead>
                                    {/* end::Table head */}
                                    {/* begin::Table body */}
                                    <tbody>
                                        {rows.length > 0 &&
                                            rows.map((item:any, key:number) => renderRows(item, key))
                                        }
                                    </tbody>
                                    {/* end::Table body */}
                                </table>
                                {rows.length === 0 &&
                                    <div className="alert alert-warning">
                                        You have not requested any withdrawals.
                                    </div>
                                }
                                {/* end::Table */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { MeusSaques }