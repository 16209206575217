import React from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {baseFrontUri} from '../../../../Helpers/Requests'
export default function useGTranslator() {
  const domain = baseFrontUri.replace('https://backoffice.', '')

  function setCookie(b, h, c, f, e) {
    var a
    if (c === 0) {
      a = ''
    } else {
      var g = new Date()
      g.setTime(g.getTime() + c * 24 * 60 * 60 * 1000)
      a = 'expires=' + g.toGMTString() + '; '
    }
    var e = typeof e === 'undefined' ? '' : '; domain=' + e
    document.cookie = b + '=' + h + '; ' + a + 'path=' + f + e
  }

  function getCookie(d) {
    var b = d + '='
    var a = document.cookie.split(';')
    for (var e = 0; e < a.length; e++) {
      var f = a[e].trim()
      if (f.indexOf(b) == 0) {
        return f.substring(b.length, f.length)
      }
    }
    return ''
  }

  function getActualValue() {
    let langs = getLangs()
    var cookie = getCookie('googtrans')
    if (!cookie) return langs[0]

    var val = cookie.replace('/pt/', '')

    let actualValue = null
    langs.forEach((lng) => {
      if (lng.value === val) {
        actualValue = lng
      }
    })
    return actualValue
  }

  function getLangs() {
    return [
      /*{
        label: 'Português - BR',
        value: 'pt',
        flag: toAbsoluteUrl('/media/flags/brazil.svg'),
      },*/
      {
        label: 'English - USA',
        value: 'en',
        flag: toAbsoluteUrl('/media/flags/united-states.svg'),
      },
      {
        label: 'Português - PT',
        value: 'pt',
        flag: toAbsoluteUrl('/media/flags/portugal.svg'),
      },
      {
        label: 'Espanõl - ES',
        value: 'es',
        flag: toAbsoluteUrl('/media/flags/spain.svg'),
      }
    ]
  }

  const changeLang = (lang) => {
    if (lang == 'pt') {
      setCookie('googtrans', lang, 0, '/', '.' + domain)
      setCookie('googtrans', '', 0, '/')
      window.location.reload()
      return true
    }

    setCookie('googtrans', '/pt/' + lang, 999, '/', '.' + domain)
    setCookie('googtrans', '/pt/' + lang, 999, '/')
    window.location.reload()
  }

  return {
    changeLang,
    getLangs,
    getActualValue,
  }
}
