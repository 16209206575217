import { useState, useEffect, useContext } from 'react'
import { FormControl, Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import { toast } from 'react-hot-toast'
import ConfigContext from '../../contexts/Config/ConfigContext'
import { useCopyToClipboard } from 'usehooks-ts'
import { baseUrl, sendRequest } from '../../../Helpers/Requests'
import { ModalComprovante } from './ModalComprovante'
import { useNavigate } from 'react-router-dom'
import {QRCodeSVG} from 'qrcode.react'

const ModalPixSendPlanoAUTOMATICO = (props: any) => {
  const navigate = useNavigate()

  const [value, copy] = useCopyToClipboard()
  const config = useContext(ConfigContext)
  const [paymentProofModal, setPaymentProofModal] = useState(false)
  const { paymentLink, valor, pixModal, hidePixModal, subscription = null, redirectAfter = null, paymentData = [] } = props

  const handleCopyLink = () => {
    copy(paymentLink)
    toast.success('chave copiada com sucesso!')
  }

  const handlePaymentProof = () => {
    hidePixModal()
    return setPaymentProofModal(true)
  }

  let listenerPayment: any = null

  if (subscription != null && subscription.method === 'RASPay') {
    listenerPayment = setInterval(async () => {
      runListener()
    }, 1500)
  }

  const runListener = async () => {
    const req = await sendRequest(`planos/get-payment-data/${subscription.id}`, 'GET', {}, null, null, true)
    console.log('LINK DE PAGAMENTO',paymentLink)
    if (req.paid) {
      hidePixModal()
      clearInterval(listenerPayment)

      navigate(redirectAfter)
    }
  }

  return (
    <>
      {subscription != null && subscription.method === 'RASPay' &&
        <Modal
          id='kt_modal_create_app'
          tabIndex={-1}
          aria-hidden='true'
          dialogClassName='modal-dialog modal-dialog-centered mw-500px'
          show={pixModal}
          onHide={hidePixModal}
          backdrop={true}
        >
          <div className='modal-header'>
            <h2>Pagamento via PIX</h2>
            {/* begin::Close */}
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={hidePixModal}>
              <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
            </div>
          </div>
          <div className='modal-body py-lg-5 px-lg-10'>
            <div className='row mt-0'>
              <div className='alert alert-warning col-lg-12 text-center'>
                Para concluir seu pagamento, escaneie o qr code abaixo{' '}
                ou copie e cole a chave pix no app do seu banco e realize o pagamento.
                {/* <b>${parseFloat(valor).toFixed(2)}</b> */}
              </div>
            </div>
            <h5>
              {/* <b>Nome do destinatário: </b> {config.pix_nome} {subscription.pix_payment.qr_code}*/}
            </h5>
            <div className='row justify-content-center'>
              <div className='col-12 d-flex justify-content-center'>
                {/* <img src={baseUrl(config.pix_qrcode)} style={{maxWidth: '200px'}} /> */}
                {/* <img src={"data:image/jpeg;base64, " + paymentData.qr_code} style={{ maxWidth: '200px' }} className='mb-5' /> */}

                {/* <QRCode 
                  size={256}
                  value={paymentLink}
                  viewBox={`0 0 256 256`}
                /> */}
              </div>
            </div>
            <div className='row w-100'>
              <div className='col-lg-10'>
                <FormControl readOnly={true} value={paymentLink} />
              </div>
              <div className='col-lg-1'>
                <div className='d-grid'>
                  <button
                    onClick={handleCopyLink}
                    className='btn btn-success btn-icon-success btn-text-default'
                  >
                    Copiar
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='modal-footer py-lg-10 px-lg-10'>
            {/* <button className='btn btn-primary' style={{ width: '100%' }} onClick={handlePaymentProof}>
            Enviar Comprovante
          </button> */}
            <button className='btn btn-danger' style={{ width: '100%' }} onClick={hidePixModal}>
              Cancelar
            </button>
          </div>
        </Modal>
      }

      {/* {paymentRequest && (
        <ModalComprovante
          paymentProofModal={paymentProofModal}
          paymentRequest={paymentRequest}
          hidePaymentProofModal={() => setPaymentProofModal(false)}
          redirectAfter={redirectAfter}
        />
      )} */}
    </>
  )
}

export { ModalPixSendPlanoAUTOMATICO }
