/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef} from 'react'
import {KTSVG} from '../../../../helpers'
import {getCSSVariableValue} from '../../../../assets/ts/_utils'
import {useThemeMode} from '../../../layout/theme-mode/ThemeModeProvider'
import {initChart} from '../../../../../app/components/chatInitializer'

type Props = {
  className: string
  chartSize?: number
  chartLine?: number
  chartRotate?: number
  binario?: any
  diario?: any
  indicacao?: any
  unilevel?: any
  residual?: any
  total?: any
  config?: any
  loaded?: any
}

const CardsWidget17: FC<Props> = ({
  className,
  chartSize = 70,
  chartLine = 11,
  chartRotate = 145,
  total,
  residual,
  unilevel,
  indicacao,
  diario,
  binario,
  config,
  loaded,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  useEffect(() => {
    refreshChart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, total])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    setTimeout(() => {
      initChart(config, {
        diario: parseFloat(diario),
        indicacao: parseFloat(indicacao),
        binario: parseFloat(binario),
        unilevel: parseFloat(unilevel),
      })
    }, 10)
  }

  return (
    <div className={`card card-flush ${className} ${loaded ? '' : 'overlay overlay-block'}`}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <span className='fs-5 fw-semibold text-gray-700 me-1 align-self-start'>{config.moeda.prefixo}</span>

            <span className='fs-4hx fw-bold text-dark me-2 lh-1 ls-n2'>
              {parseFloat(total).toFixed(2)}
            </span>
          </div>
          <span className='text-gray-700 pt-1 fw-semibold fs-6'>Total winnings</span>
        </div>
      </div>

      <div className='card-body pt-0 pb-4 d-flex flex-wrap align-items-center justify-content-center'>
        <div className='overlay-wrapper'>
          <div className='d-flex flex-center me-5 pt-2 justify-content-center align-items-center'>
            <div
              id='kt_card_widget_17_chart'
              ref={chartRef}
              style={{minWidth: chartSize + 'px', minHeight: chartSize + 'px'}}
              data-kt-size={chartSize}
              data-kt-line={chartLine}
            ></div>
          </div>

          
        </div>
        {!loaded && (
          <div className='overlay-layer rounded bg-dark bg-opacity-5'>
            <div className='spinner-border text-primary' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        )}
      </div>

      <div className="card-footer">
      <div className='d-flex flex-column content-justify-center flex-row-fluid'>
            {config.diario && (
              <div className='d-flex fw-semibold align-items-center'>
                <div className='bullet w-8px h-3px rounded-2 bg-success me-3'></div>
                <div className='text-gray-700 flex-grow-1 me-4'>Daily total</div>
                <div className='fw-bolder text-gray-700 text-xxl-end'>
                {config.moeda.prefixo} {parseFloat(diario).toFixed(2)}
                </div>
              </div>
            )}

            {config.indicacao && (
              <div className='d-flex fw-semibold align-items-center my-1 mb-0'>
                <div className='bullet w-8px h-3px rounded-2 bg-primary me-3'></div>
                <div className='text-gray-700 flex-grow-1 me-4'>Total Indication</div>
                <div className='fw-bolder text-gray-700 text-xxl-end'>
                {config.moeda.prefixo} {parseFloat(indicacao).toFixed(2)}
                </div>
              </div>
            )}

            {config.binario && (
              <div className='d-flex fw-semibold align-items-center my-1'>
                <div
                  className='bullet w-8px h-3px rounded-2 me-3'
                  style={{backgroundColor: getCSSVariableValue('--kt-warning')}}
                ></div>
                <div className='text-gray-700 flex-grow-1 me-4'>Total Binary</div>
                <div className=' fw-bolder text-gray-700 text-xxl-end'>
                {config.moeda.prefixo} {parseFloat(binario).toFixed(2)}
                </div>
              </div>
            )}

            {config.unilevel && (
              <div className='d-flex fw-semibold align-items-center'>
                <div
                  className='bullet w-8px h-3px rounded-2 me-3'
                  style={{backgroundColor: getCSSVariableValue('--kt-danger')}}
                ></div>
                <div className='text-gray-700 flex-grow-1 me-4'>Total Unilevel</div>
                <div className=' fw-bolder text-gray-700 text-xxl-end'>
                {config.moeda.prefixo} {parseFloat(unilevel).toFixed(2)}
                </div>
              </div>
            )}
          </div>
      </div>
    </div>
  )
}

export {CardsWidget17}
